.form-box {
    background: white;
    text-align: left;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--color-gray);
    padding: 0;
    line-height: 1;
    position: relative;
    cursor: pointer;
}

.form-box .form-control{
    cursor: pointer;
}

.form-box__label {
    display: block;
    color: var(--color-dark-gray);
    padding: 10px;
    padding-bottom: 0;
}
.form-box__content{
    display: block;
    font-family: var(--font-default-medium);
    font-weight: var(--font-default-medium-weight);
    font-size: 17px;
    padding: 10px;
    padding-top: 8px;
    padding-right: 24px;
}
.form-box__content,
.form-box__content:focus,
.form-box__content[readonly]{
    background: transparent;
}
.form-box input,
.form-box select {
    padding-left: 10px;
    padding-right: 10px;
}

@media screen and (min-width: 767px) {
    .form-box__label {
        font-size: 13px;
    }

}

/*styling adjustments for form-controls & input-groups*/
textarea.form-box__content,
select.form-box__content,
input.form-box__content,
.form-control.form-box__content {
    border-top: none;
    border-right: none;
    border-left: none;
    width: 100%;
    margin-bottom: -1px;
}
select.form-box__content,
select.form-box__content:not([size]):not([multiple]), /*override bootstrap selector*/
input.form-box__content,
.form-box__content.input-group,
.form-box__content.input-group input,
.form-box__content.input-group select,
.form-box__content.input-group select:not([size]):not([multiple]) /*override bootstrap selector*/ {
    height: 36px;
}
select.form-box__content:focus,
select.form-box__content,
.form-box__content select:focus,
.form-box__content select {
    padding-top: 2px;
    padding-bottom: 6px;
    padding-right: 30px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
select.form-box__content::-ms-expand,
.form-box__content select::-ms-expand {
    display: none;
}

.form-box__content.input-group {
    display: flex;
    padding: 0;
    background: transparent;
    margin-bottom: -1px;
}
.form-box__content.input-group select {
    padding-top: 6px;
    padding-bottom: 2px;
}


/*icon*/
.form-box__icon {
    color: var(--color-primary);
    position: absolute;
    right: 9px;
    bottom: 9px;
    font-size: 16px;
}
.form-box__icon.demi-icon-chevron-down {
    font-size: 6px;
    bottom: 12px;
}
.form-box__select-icon {
    position: absolute;
    bottom: 15px;
    right: 10px;
    pointer-events: none;
    font-size: 7px;
}
.form-box__select-icon--small {
    font-size: 5px;
}


/*collapsible*/
.form-box--collapsible:not(.collapsed) {
    border-bottom-color: var(--color-primary);
}
.form-box--collapsible:not(.collapsed) .form-box__content {
    color: var(--color-primary);
}
.form-box--collapsible .form-box__icon {
    transition: transform 120ms ease-out;
}
.form-box--collapsible:not(.collapsed) .form-box__icon {
    transform: rotate(180deg);
}

.form-box__detail {
    background: #ffffff;
}


/*gray*/
.form-box--gray {
    background: var(--color-light-gray);
    border-top: 1px solid #d8d8d8;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
}
.form-box--gray .form-control:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px var(--color-light-gray) inset !important;
}

/*readonly*/
.form-box--readonly {
    opacity: 0.6;
}


/*error state*/
.form-box:after {
    content: var(--demi-icon-warning);
    font-family: var(--demi-icon-warning-font-family);
    color: var(--color-danger);
    position: absolute;
    top: 9px;
    right: 7px;
    font-size: 12px;

    display: inline-block;
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    transform: scale(0);
    transform-origin: center;
    opacity: 0;
    transition: opacity 120ms, transform 120ms cubic-bezier(.16,.46,.83,1.36);
}
.has-error .form-box:after {
    opacity: 1;
    transform: scale(1);
}
.has-error .form-box__content,
.has-error .input-group .form-control {
    border-color: var(--color-danger);
}

/*success state*/
.has-success .form-box__content,
.has-error .input-group .form-control {
    border-color: var(--color-success);
}

.form-box__addon-button {
    height: 100%;
    border-radius: 0;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    line-height: 0;

    @media screen and (max-width: 767px) {
        padding-top: .25rem;
        padding-bottom: .25rem;
        font-size: 0.85rem;
    }
}