.overlay {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-top: 0;
    transform: translateX(100%);
    transition: transform 180ms ease-out, visibility 0.001ms 180ms, opacity 180ms ease;
    background: white;
    z-index: 1100;
    overflow: hidden;
    will-change: transform;
    visibility: hidden;
    opacity: 0;
}
/* vertically and horizontally centered overlay*/
.overlay.overlay--centered {
    bottom: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-60%);
    width: 500px;
}
.overlay.overlay--centered.is-open {
    transform: translate(-50%,-50%);
}

/* horizontally centered overlay*/
.overlay.overlay--centered-top {
    bottom: auto;
    top: 50px;
    left: 50%;
    width: 500px;
    transform: translate(-50%,-10%);
}
.overlay.overlay--centered-top.is-open {
    transform: translate(-50%,0);
}

.overlay--centered > .overlay__content,
.overlay--centered-top > .overlay__content {
    position: static;
    min-height: 530px;
}
/* all overlays in a centered one must have full width */
.overlay.overlay--centered .overlay,
.overlay.overlay--centered-top .overlay{
    width: 100%;
    left: 0;
    right: 0;
}
.overlay.is-open {
    transform: translateX(0);
    visibility: visible;
    opacity: 1;
    transition: transform 180ms ease-out, visibility 0.001ms, opacity 180ms ease;
}
.overlay__content {
    position: absolute;
    left: 0;
    right: 0;
    top: 60px;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
.overlay__header {
    height: 60px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 500;
    display: flex;
    align-items: center;
    font-size: 17px;
    font-family: var(--font-default-medium);
    font-weight: var(--font-default-medium-weight);
}
.overlay__header-item {
    padding: 20px;
    min-width: 0; /*to allow text-truncate*/
}
.overlay__header-item + .overlay__header-item--bordered {
    border-left: 1px solid var(--color-dark-medium-light);
}

.overlay__header-submit-btn {
    color: var(--color-primary);
}
.overlay__header-submit-btn:disabled,
.overlay__header-submit-btn.disabled {
    color: var(--color-gray);
}

@media (min-width: 768px) {
    .overlay {
        width: 400px;
        left: auto;
        box-shadow: 0 1px 3px rgba(0,0,0,0.3);
    }

    .overlay__content {
        font-size: 14px;
    }

    .list-with-map-container #search-overlay {
        position: absolute;
    }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /*IE 11 nested overlay fix*/
    .overlay .overlay {
        position: absolute;
    }
}
