.teaser__acco-title {
    font-family: var(--font-decorative);
    text-transform: uppercase;
    @media (min-width: 768px) {
        font-size: calc(16rem/16);
    }
}
.offer-teaser .price {
    font-family: var(--font-decorative);
}
.acco-list .teaser__detail-btn {
    padding:0;
    display:block;
}

.demi-related-teaser {
    display: flex;
    flex-direction: column;
    flex: auto;
    cursor: pointer;
    border-radius: .3125rem;
    position:relative;
    box-shadow: 0 9px 15px 0 rgba(32,32,32,.6);
    overflow: hidden;
    position: relative;
    @media screen and (max-width: 767px) {
        margin-bottom: calc(20rem/16);
    }
}
.demi-related-teaser__btn {
    background-color:#fff;
    color: var(--color-primary);
    margin-top: calc(10rem/16);
    width: 100%;
    display:block;
    @media screen and (min-width: 768px) and (max-width: 991px) {
        padding: .5rem .75rem;
        font-size: 1rem;
    }
    @media screen and (max-width: 767px) {
        font-size: calc(13rem / 16);
        padding: .25rem .5rem;
    }
}
.demi-related-teaser__title a:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0);
}
.demi-related-teaser__title {
    font-size: calc(14rem / 16);
    margin-bottom: .5rem;
    font-family: var(--font-decorative);
    color: #fff;
    @media screen and (min-width: 768px) {
        font-size: calc(18rem/16);
        margin-bottom: 1.25rem;
    }

    @media screen and (max-width: 767px) {
        word-break: break-word;
    }
}
.demi-related-teaser__body {
    padding: .5rem .5rem .75rem .5rem;
    display: flex;
    flex: auto;
    flex-direction: column;
    justify-content: space-between;
    background: var(--color-primary);
    color: #fff;

    @media screen and (min-width: 992px) {
        padding: 1.25rem;
    }

    @media screen and (min-width: 768px) {
        padding: .5rem;
    }
}
.demi-related-teaser {
    display: flex;
    flex-direction: column;
    flex: auto;
    cursor: pointer;
    border-radius: .3125rem;
    position:relative;
    box-shadow: 0 9px 15px 0 rgba(32,32,32,.6);
    overflow: hidden;
    position: relative;
}
.demi-related-teaser__img:before{
    content: "";
    position: absolute;
    width: 100%;
    height: 80%;
    bottom: -.0625rem;
    z-index: 1;
    left: 0;
    opacity: 1;
    background: linear-gradient(180deg,rgba(0,54,16,0), rgba(0, 54, 16, 0.44), #003610);
    transition: transform .3s ease,opacity .3s ease;
    transform-origin: bottom;
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.demi-related-teaser__img{
    position: relative;
    overflow: hidden;
}
.demi-related-teaser__img .embed-responsive-item{
    transition: transform 0.4s ease;
    object-fit:cover
}
.demi-related-teaser__price {
    font-size: 1.5rem;
    line-height:1;
    text-align: right;
    font-family: var(--font-decorative);
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
    }
}
.demi-related-teaser__price-label {
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
    }
}
