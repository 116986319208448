
@import '../../../../../../../../public/static/css/style/variables.css';
@import '../../../../../../../../public/static/css/style/icons.css';
@import '../../../../../../../../public/static/css/style/mediaqueries.css';
@import '../../../../../../../../public/static/css/style/font-face.css';

@import '../../../../../../../../public/static/css/style/base.css';
@import '../../../../../../../../public/static/css/style/base-layout.css';
@import '../../../../../../../../public/static/css/style/base-typo.css';
@import '../../../../../../../../public/static/css/style/container.css';
@import '../../../../../../../../public/static/css/style/custom-cursor.css';
@import '../../../../../../../../public/static/css/style/select.css';
@import '../../../../../../../../public/static/css/style/form.css';
@import '../../../../../../../../public/static/css/style/stretch-link.css';
@import '../../../../../../../../public/static/css/style/floating-label.css';

/*!* nav *!*/
@import '../../../../../../../../public/static/css/style/main-navbar.css';
@import '../../../../../../../../public/static/css/style/main-navbar__toggle.css';
@import '../../../../../../../../public/static/css/style/main-navbar__overlay.css';
@import '../../../../../../../../public/static/css/style/nav-overlay.css';
@import '../../../../../../../../public/static/css/style/mega-nav/mega-nav.css';
@import '../../../../../../../../public/static/css/style/mega-nav/mega-nav--level-0-desktop.css';
@import '../../../../../../../../public/static/css/style/mega-nav/mega-nav--level-1-desktop.css';
@import '../../../../../../../../public/static/css/style/mega-nav/mega-nav--level-2-desktop.css';
@import '../../../../../../../../public/static/css/style/mega-nav/mega-nav-mobile.css';
@import '../../../../../../../../public/static/css/style/nav-teaser.css';
@import '../../../../../../../../public/static/css/style/scrolling-row.css';
@import '../../../../../../../../public/static/css/style/content-block.css';
@import '../../../../../../../../public/static/css/style/custom-cursor.css';
@import '../../../../../../../../public/static/css/style/img-teaser.css';
@import '../../../../../../../../public/static/css/style/quickfinder.css';
@import '../../../../../../../../public/static/css/style/language-switch.css';
@import '../../../../../../../../public/static/css/style/overlay.css';
@import '../../../../../../../../public/static/css/style/form.css';
@import '../../../../../../../../public/static/css/style/floating-label.css';
@import '../../../../../../../../public/static/css/style/btn.css';
@import '../../../../../../../../public/static/css/style/vertical-gutter.css';
@import '../../../../../../../../public/static/css/style/scrolling-area.css';
@import '../../../../../../../../public/static/css/style/tag-link.css';
@import '../../../../../../../../public/static/css/style/accessibility-item.css';
@import '../../../../../../../../public/static/css/style/demi-embedded/young-styria.css';
@import '../../../../../../../../public/static/css/style/flatrate-teaser.css';
@import '../../../../../../../../public/static/css/style/accordion.css';


/*!*footer *!*/
@import '../../../../../../../../public/static/css/style/footer-main.css';
@import '../../../../../../../../public/static/css/style/footer-socials.css';
@import '../../../../../../../../public/static/css/style/footer-copyright.css';

@import '../../../../../../../../public/static/css/style/oocss.css';

.screen-wrapper {
    height: 100vh;
    padding-top: calc(118rem/16);
    @media screen and (max-width: 767px) {
        padding-top: calc(60rem/16);
    }
}
.main-header--with-meta-link ~ .screen-wrapper {
    padding-top: 9.25rem;

    @media screen and (max-width: 767px) {
        padding-top: 9.06rem;
    }
}

.form-group--dark .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.74rem + 2px);
    padding: .87rem 1.56rem;
    font-family: var(--font-default-bold);
    font-size: .875rem;
    font-weight: var(--font-weight-bold);
    line-height: 1.5;
    color: #fff;
    background-color: var(--color-secondary);
    background-clip: padding-box;
    border: 1px solid #fff;
    border-radius: 1.875rem;
}

.btn-primary-light.btn {
    background-color: var(--color-primary-light);
    border-color: var(--color-primary-light);
    color:#fff;
}
.flatpickr-calendar.animate.open {
    animation: none;
}
.main-content--no-hero {
    padding-top: calc(170rem/16);
    @media screen and (max-width: 767px) {
        padding-top: calc(70rem/16);
    }
}
.main-content--no-hero-small {
    padding-top: 9.25rem;

    @media screen and (max-width: 767px) {
        padding-top: 9.06rem;
    }
}
h1, .h1 {
    line-height: 1.1 !important;
}
.number-spinner__input {
    padding:0;
    color: var(--color-text-default);
}
.room-row__book-now-btn {
    width: 100%;
}
.accommodation-contact .btn {
    @media screen and (min-width: 768px) {
        min-width: calc(280rem/16);
        font-size: calc(17rem/16);
    }
}
.accommodation-contact h4 {
    text-transform: uppercase;
}
.accommodation-contact h4, .accommodation-contact .h3 {
    font-family: var(--font-decorative);
}
.best-offer-card__body .btn {
    @media screen and (min-width: 768px) {
        font-size: calc(17rem/16);
        width: 100%;
    }
}
.js-corridor__search-info-content .col-auto {
    flex: 0 0 100%;
    max-width: 100%;
}
.offer-box__title {
    margin-top: calc(10rem/16);
    font-family: var(--font-decorative);
    text-transform: uppercase;
}
.accordion--demi-additionalservice .card {
    box-shadow: 0 10px 20px 0 rgba(0,0,0, 0.3);
    margin-bottom: .9375rem !important;
}
.accordion--demi-additionalservice .card-header {
    margin-bottom: 0;
    line-height: 1.4;
    padding: 1.875rem 1.875rem 1.4375rem;
    font-size: 1.125rem;
    text-transform: uppercase;
    background-color:#fff;
}
.accordion--demi-additionalservice .card-header h5 {
    font-family: var(--font-decorative);
}
.current-information {
    background-color: var(--color-secondary);
    color:#fff;
}
.current-information.card .wysiwyg a {
    color: var( --color-primary-light);
}
.current-information.card .wysiwyg a:hover {
    color: var( --color-primary-light);
    text-decoration: underline;
}
.current-information h4.text-primary {
    font-family: var(--font-decorative);
    text-transform: uppercase;
    color: var(--color-primary-light) !important;
}
.infrastructure__hl {
    font-family: var(--font-decorative);
    text-transform: uppercase;
    margin-bottom: calc(10rem/16);
}
.demi-event-intro {
    text-align: center;
}
.card-teaser {
    background-color:#fff;
}
.card-teaser h3 {
    font-family: var(--font-decorative);
    text-transform: uppercase;
}
.card-teaser .offer-box__price, .additional-offer-card .offer-box__price {
    font-family: var(--font-decorative);
    @media screen and (max-width: 767px) {
        font-size: calc(18rem/16);
    }
}
.room-row__book-now-btn {
    @media screen and (min-width: 768px) {
        padding: calc(10rem/16);
    }
}
.additional-offer-card {
    @media screen and (min-width: 768px) {
        background-color: var(--color-secondary);
        color:#fff;
        border-color: var(--color-secondary);
        border-radius: .3125rem;
    }
}

.additional-offer-card .modal{
    color: var(--color-default);
}

@media screen and (max-width:767px) {
    .row.row--gutter-width-10--xs {
        margin-left: -5px;
        margin-right: -5px;
    }
    .row--gutter-width-10--xs > [class^="col"],
    .row--gutter-width-10--xs > [class*=" col"],
    .row--gutter-width-10--xs.slick-slider [class^="col"],
    .row--gutter-width-10--xs.slick-slider [class*=" col"] {
        padding-left: 5px;
        padding-right: 5px;
    }
}
.position-relative {
    position: relative !important;
}
.form-control:disabled, .form-control[readonly] {
    background-color: transparent;
    opacity: 1;
}
.lg-outer .lg-item {
    /* remove loading gif */
    background-image: none;
}
.lg-icon {
    font-family: inherit;
}

@media screen and (max-width: 767px) {
    #map-overlay {
        top: 7.5rem;
    }
    .main-header--with-meta-link ~ .main-content--spacing > #map-overlay {
        top: 9.03rem;
    }
}

.custom-checkbox > label {
    /* for the custom search nearby checkbox in the booking overlay */
    display: flex;
    align-items: baseline;
    cursor: pointer;
    flex-wrap: nowrap;
}

.test-class{
    background-color: yellow;
}