.loading-spinner {
    margin-left: auto;
    margin-right: auto;
    width: 70px;
    text-align: center;
}
.loading-spinner__item {
    width: 18px;
    height: 18px;
    background-color: var(--color-primary);

    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.loading-spinner__item--1 {
    animation-delay: -0.32s;
}
.loading-spinner__item--2 {
    animation-delay: -0.16s;
}
@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1.0);
    }
}

.hide-loading-text .loading-spinner__text{
    display: none;
}

.loading-spinner--white .loading-spinner__item{
    background-color: #fff;
}
