.screen-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
}

.list-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 100%;
}

.list__items-container {
    position: relative;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    width: 700px;
    max-width: calc(100vw - 295px);

    @media (max-width: 991px) {
        width: 100%;
        max-width: none;
    }
}
.list__items-container.is-full-width {
    width: auto;
    flex: auto;
    resize: none;
    max-width: none;
}


.accommodation-map {
    position: relative;
    flex: 1;
}

@media (max-width: 991px) {
    .accommodation-map {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    .accommodation-map.is-visible {
        display: block;
    }
}


.list-with-map-container{
    flex: 1;
    position: relative;
    display: flex;
    min-height: 0;
    overflow: hidden;
}