@media screen and (min-width: 576px) {}
@media (min-width: 576px) {}
@media screen and (min-width: 768px) {}
@media (min-width: 768px) {}
@media screen and (min-width: 992px) {}
@media (min-width: 992px) {}
@media screen and (min-width: 1200px) {}
@media (min-width: 1200px) {}
@media screen and (min-width: 1300px) {}
@media (min-width: 1300px) {}
@media screen and (min-width: 1400px) {}
@media (min-width: 1400px) {}
@media screen and (min-width: 1600px) {}
@media (min-width: 1600px) {}
@media screen and (min-width: 1660px) {}