.search-info {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2);
    z-index: 1;
}

.search-info__data {
    padding: 15px 30px;
}

.search-info-item {
    border-right: 1px solid var(--color-gray-border);
    text-align: center;
    font-weight: bold;
    font-size: 18px;
}

.search-info__back-btn {
    height: 100%;
    background-color: var(--color-light-gray);
    color: var(--color-dark-medium);
    padding: 0 19px;
    border-right: 1px solid var(--color-gray-border);
}

.search-info__from-to-icon {
    font-size: 12px;
}

.search-info__hotline {
    margin-left: auto;
    margin-right: 30px;
}

.search-info__hotline__link {
    color: var(--color-primary);
    font-size: 18px;
}

.search-info__hotline__image {
    max-height: 68px;
    width: auto;
}

@media screen and (max-width: 1199px) {
    .search-info-item {
        font-size: 17px;
    }

    .search-info__data {
        padding: 15px;
    }

    .search-info__hotline {
        margin-right: 15px;
    }
}