.form-control {
    border-top: none;
    border-left: none;
    border-right: none;
    font-size: 17px;
    font-family: var(--font-default-medium);
    font-weight: var(--font-default-medium-weight);
}
.form-control:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

/* gray */
.form-control--gray,
.form-control--gray:focus {
    /*todo vars*/
    background-color: #f2f2f2;
    border-top: 1px solid #d8d8d8;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
}
.form-control--gray:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #f2f2f2 inset !important;
}

/*Input groups*/
.input-group > .form-control {
    background-color: inherit;
}

/*Gray Input groups*/
.input-group--gray {
    background-color: #f2f2f2;
    border-top: 1px solid #d8d8d8;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
}

/*input group add ons*/
.input-group-addon {
    border-top: none;
    border-left: none;
    border-right: none;
}
.input-group > .input-group-addon {
    background-color: inherit;
}
.form-control:focus ~ .input-group-addon {
    border-bottom-color: var(--color-primary);
}
.form-control:focus ~ .input-group-addon {
    border-bottom-color: var(--color-primary);
}

.has-error .input-group-addon.input-group-addon {
    border-bottom-color: var(--color-danger);
}
.has-success .input-group-addon.input-group-addon {
    border-bottom-color: var(--color-success);
}





/*form errors*/
.form-errors {
    color: var(--color-danger);
}
.form-errors .parsley-errors-list {
    display: block;
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: left;
    margin-top: 4px;
}
.form-errors .parsley-errors-list:not(.filled) {
    display: none;
}