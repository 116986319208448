:root {
    --toggle-btn-icon-width: 20px;
}
.toggle-btn,
.toggle-btn-wrapper {
    max-width: 100%;
}
.toggle-btn__icon {
    width: var(--toggle-btn-icon-width);
    text-align: left;
    transition: opacity 120ms, transform 150ms cubic-bezier(0.16, 0.46, 0.83, 1.36);
    transform: scale(0);
    color: #ffffff; /*todo*/
    vertical-align: middle;

}
.toggle-btn--has-icon .toggle-btn__content {
    display: inline-block;
    transition: transform 120ms;
    transform: translateX(calc(var(--toggle-btn-icon-width) * -.5));
    vertical-align: middle;
    white-space: normal;
}
.toggle-btn--has-icon.toggle-btn--aligned-left .toggle-btn__content {
    transform: translateX(calc(var(--toggle-btn-icon-width) * -1));
}
/*active button*/
input:checked + .toggle-btn {
    color: #ffffff;
    background-color: var(--color-primary);
}
input[disabled] + .toggle-btn {
    opacity: .6;
}
input:checked + .toggle-btn--has-icon .toggle-btn__icon {
    opacity: 1;
    transform: scale(1);
}
input:checked + .toggle-btn--has-icon .toggle-btn__content {
    transform: translateX(0);
}

/*hightlighted variant*/
.toggle-btn--highlighted {
    color: #ffffff;
    background-color: var(--color-primary);
}
input:checked + .toggle-btn--highlighted {
    background-color: var(--color-success);
}
.toggle-btn--no-truncate.toggle-btn--has-icon  {
    display: flex;
    align-items: center;
}
