.custom-checkbox {
    display: flex;
    align-items: baseline;
    cursor: pointer;
}
.custom-checkbox__input {
    /* screen reader only*/
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-checkbox__box {
    display: flex;
    overflow: hidden;
    border: 1px solid #c4c6c7;
    background: #ffffff;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    /* small vertical alignment adjustment;
     vertical align is not working in flex children */
    position: relative;
    /* vertical align for smaller and bigger font-sizes*/
    top: calc(7px - .3em);
}
.custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 7px;
    color: #ffffff;
    visibility: hidden;
}
.custom-checkbox__input:focus ~ .custom-checkbox__box {
    border-color: var(--color-primary);
}
.custom-checkbox__input:checked ~ .custom-checkbox__box {
    background: var(--color-primary);
    border-color: var(--color-primary);
}

.custom-checkbox__input:checked ~ .custom-checkbox__box.custom-checkbox__box--success {
    background: var(--color-success);
    border-color: var(--color-success);
}
.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
}

.custom-checkbox--highlight-text .custom-checkbox__input:checked ~ .custom-checkbox__text {
    color: var(--color-primary);
}

.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}
.custom-checkbox__text {
    flex: 1 1 0%;
    line-height: 1;
    min-width: 0;
}