:root {
    --corridor-bg-gray: var(--color-light-gray);
    --corridor-bg-dark-gray: #E6E6E6;
}

.corridor__head {
    position: relative;
    z-index: 1;
}

.corridor__matrix {
    padding: 0 5px;
    display: flex;
    text-align: center;
}
.corridor__left {
    /*todo name   */
    float: none;
    width: 40px;
}
.corridor__body {
    flex: auto;
    min-width: 0;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        /*IE 11 fix*/
        flex-basis: 100%;
    }
}

.corridor__col {
    width: 80px;
}
.corridor__matrix-head {
    height: 60px;
    background: linear-gradient(180deg, #FFFFFF 0%, color(var(--color-gray-border) a(25%)) 50%);
    padding-top: 21px;
}
.corridor__cell {
    border-top: 1px solid var(--color-gray-border);
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;
    padding: 2px 4px;
}
.corridor__cell-btn {
    padding: 2px 6px;
    border-radius: 6px;
    border: none;
    height: 100%;
    white-space: normal;
    background: none;
}
.corridor__cell-btn.is-selected {
    background: var(--color-success);
    color: #ffffff;
}

@media screen and (min-width: 768px) {
    .corridor__cell,
    .corridor__matrix-head {
        border-right: 1px solid var(--color-gray-border);
        padding: 0;
    }

    .corridor__cell {
        min-height: 75px;
    }

    .corridor__matrix {
        padding: 0;
    }

    .corridor__left {
        white-space: nowrap;
        width: auto;
        min-width: 108px;
    }

    .corridor__left .corridor__cell {
        padding-left: 15px;
        padding-right: 15px;
    }

    .corridor__matrix-head {
        height: 70px;
        background: var(--corridor-bg-gray);
        padding: 6px;
    }

    .corridor__matrix-head-day {
        font-size: 24px;
        line-height: 1;
    }

    .corridor__cell-btn {
        border-radius: 0;
        width: 100%;
        padding: 7px 5px 10px;
    }

    .corridor__cell-btn.is-selected {
        background: color(var(--color-success) a(10%));
        color: var(--color-success);
    }

    .corridor__from {
        display: block;
        color: var(--color-dark-gray);
    }

    .is-selected .corridor__from {
        color: var(--color-success);
    }

    .corridor__matrix-head--sat,
    .corridor__matrix-head--sun {
        background-color: var(--corridor-bg-dark-gray);
    }

    .corridor__cell--sat,
    .corridor__cell--sun {
        background-color: var(--corridor-bg-gray);
    }

}

/*.corridor .slick-track {*/
    /*display: inline-block; !*to trigger block formatting context*!*/
/*}*/
