.noUi-horizontal {
    height: 6px;
}

.noUi-target {
    background: var(--color-gray);
    box-shadow: none;
    border: none;
}

.noUi-connect {
    background: var(--color-primary);
}

.noUi-horizontal .noUi-handle {
    height: 24px;
    width: 24px;
    left: -12px;
    top: -10px;
    border-radius: 50%;
}

/* Slider size and handle placement;
 */
html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
    right: -12px;
    left: auto;
}

/* Handle stripes;
 */
.noUi-handle:before {
    content: var(--demi-icon-bars);
    font-family: var(--demi-icon-bars-font-family);
    display: inline-block;
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
    font-size: 10px;
    color: var(--color-primary);

    height: 10px;
    top: 6px;
    left: 6px;
    width: auto;
}

.noUi-handle:after {
    display: none;
}

.noUi-horizontal.noUi-extended.noUi-target {
    padding: 0 12px;
}
.noUi-horizontal.noUi-extended .noUi-base:before,
.noUi-horizontal.noUi-extended .noUi-base:after {
    width: 12px;
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    display: block;
}
.noUi-horizontal.noUi-extended .noUi-base:before {
    left: -12px;
}
.noUi-horizontal.noUi-extended .noUi-base:after {
    left: 100%;
}
