.accommodation-map__canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--color-dark-medium-light);
}

.accommodation-map__canvas.is-drawing-active,
.accommodation-map__canvas.is-drawing-active * {
    cursor: crosshair;
}

@media (max-width: 767px) {
    .accommodation-map__detail {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fff;
        min-height: 120px;
        transform: translateY(100%);
        transition: transform 140ms ease-out;
        will-change: transform;
    }
    .accommodation-map__detail.is-open {
        transform: translateY(0);
    }
}

@media (min-width: 768px) {
    .accommodation-map__detail:not(.is-open) {
        display: none;
    }

}


/* controls */
.accommodation-map__controls {
    position: absolute;
    bottom: 30px;
    right: 20px;
}
.accommodation-map__controls--top-left {
    left: 20px;
    top: 20px;
    right: auto;
    bottom: auto;
}
.accommodation-map__controls--top-right {
    top: 20px;
    bottom: auto;
}

.accommodation-map__control {
    font-size: 16px;
    padding: 12px;
    width: 40px;
    height: 40px;
    border: 0;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2);
}

.accommodation-map__control--w-a {
    width: auto;
}

.btn-group-vertical > .accommodation-map__control + .accommodation-map__control {
    border-top: 1px solid var(--color-gray-border);
}

.btn-group-vertical > .accommodation-map__control + .accommodation-map__control.accommodation-map__control {
    margin-top: 0;
}

@media screen and (max-width: 767px) {
    .accommodation-map__controls--top-left {
        top: 10px;
        left: 10px;
        bottom: auto;
    }

    .accommodation-map__controls--top-full-width {
        top: 10px;
        left: 10px;
        right: 10px;
        bottom: auto;
    }

}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .accommodation-map:not(.accommodation-map--full) .accommodation-map__controls {
        display: none;
    }
}

/* infobox */
.accommodation-map__infobox {
    background: white;
    box-shadow: 0 1px 6px 0 rgba(0,0,0,0.4);
    width: 250px;
}

.map-infobox__body {
    padding: 10px;
    font-size: 16px;
}

.map-infobox__title {
    color: var(--color-primary);
}

.map-infobox__price {
    color: var(--color-success);
}


/* close button */
.accommodation-map__infobox > img {
    position: absolute !important;
    right: 5px;
    top: 5px;
    z-index: 1;
}

.map-tooltip {
    position: absolute;
    white-space: nowrap;
    background: white;
    padding: 12px 15px;
    transform: translateY(-50%);
    top: 50%;
    font-size: 11px;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.2);
}

@media (min-width: 1200px) {
    .map-tooltip {
        font-size: 13px
    }
}

.map-tooltip--left {
    right: 100%;
    margin-right: 15px;
}

.map-tooltip--left:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    right: 0;
    top: 50%;
    border: 10px solid transparent;
    border-right: 0;
    border-left: 10px solid white;
    transform: translate(100%, -50%);
}

@media (max-width: 1439px) {
    .accommodation-map__controls--drawing {
        margin-top: 10px;
    }

    .map-tooltip--bottom-left-md-down {
        right: 0;
        top: 100%;
        margin-top: 15px;
        margin-right: 0;
        transform: none;
    }

    .map-tooltip--bottom-left-md-down:after {
        content: '';
        position: absolute;
        display: block;
        width: 0;
        right: 10px;
        top: 0;
        border: 10px solid transparent;
        border-top: 0;
        border-bottom: 10px solid white;
        transform: translate(0,-100%);
    }
}

@media (max-width: 1199px) {
    .accommodation-map__clear-btn {
        position: absolute;
        top: 100%;
        right: 0;
        margin-right: 0 !important;
        margin-top: 10px;
    }
    
}

