@media (min-width: 768px) {
    .additional-service__block {
        margin-top:1.5rem;
        padding-bottom:1.5rem;
        border-bottom:1px solid;
        border-bottom-color: var(--color-gray-border);
    }
    .additional-service__hl {
        margin-bottom:1rem;
        color:var(--color-primary);
    }
    .additional-offer-card--sticky {
        position: sticky;
        top: calc(165rem/16);
        right:0;
        z-index:50;
    }
}

@media (max-width: 767px) {
    .additional-offer-card--sticky {
        margin:1rem auto;
    }
    .container--additional-service-related {
        border-top:1.5rem solid var(--color-light-gray);
    }
    .container--additional-service-detail {
        background: var(--color-light-gray);
    }
    .additional-offer-card.additional-offer-card,
    .additional-service__block {
        margin-bottom:1.5rem;
        border:0;
    }
    .additional-service__hl {
        font-size:17px;
        margin-bottom:.25rem;
    }
    .additional-offer-card__body,
    .additional-service__body {
        padding: 1rem;
        background:#fff;
        box-shadow: 0 1px 3px 0 rgba(0,0,0,.2);
    }

    .additional-offer-card__body{
        margin-top: calc(15rem/16);
    }
}


.additional-offer-card{
    @media screen and (min-width: 768px){
       min-height: calc(350rem/16);
    }
}
.additional-offer-card .ui-datepicker td a,
.additional-offer-card .ui-datepicker td span{
    color: #000;

    @media screen and (min-width: 768px){
        color: #fff;
    }
}

.additional-offer-card .ui-datepicker td a.ui-state-active{
    @media screen and (max-width: 767px){
       color: #fff;
    }
}

.additional-offer-card .ui-datepicker td{
    @media screen and (min-width: 768px){
        border-left: 1px solid #323232;
        border-bottom: 1px solid #323232;
    }

}

.additional-offer-card .ui-datepicker tbody{
    @media screen and (min-width: 768px){
        border: 1px solid #323232;
    }
}


.additional-offer-card .ui-datepicker .is-checkin>a,
.additional-offer-card .ui-datepicker .is-checkin>span,
.additional-offer-card .ui-datepicker .is-checkout>a,
.additional-offer-card .ui-datepicker .is-checkout>span,
.additional-offer-card .ui-datepicker .is-in-range>a,
.additional-offer-card .ui-datepicker .is-in-range>span {
    background-color: #454545;
}

.additional-offer-card .loading-spinner{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.package-group--additionalservice .card-body{
    padding: calc(12rem/16);
    background: #f7f3ef;
    
    @media screen and (max-width: 767px){
        border: 1px solid #ebebeb;
    }
}

.package-group--additionalservice .package-group__title{
    font-size: calc(15rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(17rem/16);
    }
}

.package-group--additionalservice .package-group__details{
    font-size: calc(14rem/16);
}

.package-group--additionalservice .package-group__price{
    font-size: calc(20rem/16);
    @media screen and (max-width: 767px){
        line-height: 1;
        font-size: calc(16rem/16);
    }
}

.package-group--additionalservice .btn.package-group__btn{
    padding: calc(10rem/16);
}

.additional-offer-card .loading-overlay{
    position: absolute;
}

.additional-services__date{
    font-size: calc(14rem/16);
    margin-bottom: calc(7rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(10rem/16);
    }
}
