:root {
    --rating-trustyou-primary: var(--color-success);
    --rating-trustyou-star-gray: var(--color-gray-border);
}

.rating {
    line-height: 1;
    font-family: var(--font-default-medium);
    font-weight: var(--font-default-medium-weight);
    color: var(--rating-trustyou-primary);
}
.rating__score {
    position: relative;
    display: inline-block;
    padding: 2px 4px;
    background: var(--rating-trustyou-primary);
    color: #ffffff;
    margin-right: 2px;
    margin-bottom: 5px;
}
.rating__score:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 0;
    border-color: var(--color-success) transparent transparent transparent;
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: -1px;
}
.rating__count {
    margin-left: .25rem;
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
}

.rating--white {
    color: #ffffff;
}

.rating--md .rating__score {
    width: 45px;
    height: 44px;
    font-size: 22px;
    text-align: center;
    padding: 11px 5px 0;
    vertical-align: top;
    margin-right: 5px;
}

.rating--md .rating__score:after {
    border-width: 6px;
    border-color: var(--rating-trustyou-primary) transparent transparent var(--rating-trustyou-primary);
}

.rating--md .rating__content {
    display: inline-block;
    vertical-align: top;
}

.rating--md .rating__text {
    color: #505050;
    font-size: 19px;
}

.rating--md .rating__count {
    font-size: 11px;
    margin-left: 0;
}

.rating--md .rating-stars {
    margin-top: 1px;
}

.rating-stars {
    font-size: 0;
}

.rating-star {
    position: relative;
    display: inline-block;
    color: var(--color-success);
    font-size: 12px;

    font-family: var(--demi-icon-star-font-family);
    display: inline-block;
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.rating-star:before {
    content: var(--demi-icon-star);
}

.rating-star--half,
.rating-star--empty {
    color: var(--rating-trustyou-star-gray);
}

.rating-star--half:after {
    position: absolute;
    left: 0;
    content: var(--demi-icon-star);
    color: var(--color-success);
    width: 7px;
    width: calc(50% + 1px);
    overflow: hidden;
}

.trustyou-logo {
    width: 96px;
    height: 10px;
    background-image: url(/static/demi/debug/img/trustyou-logo.png);
    background-repeat: no-repeat;

}