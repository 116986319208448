:root {
    --number-spinner-button-bg-color: var(--color-primary);
    --number-spinner-button-color: #fff;
}

.number-spinner {
    display: inline-block;
    border-radius: 4px;
    overflow: hidden;
}
.number-spinner__button {
    background: var(--number-spinner-button-bg-color);
    color: var(--number-spinner-button-color);
}
.number-spinner__input,
.input-group .number-spinner__input {
    width: 50px;
    min-width: 0;
    flex: 0 0 51px;
    text-align: center;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
}
.number-spinner__input::-webkit-inner-spin-button,
.number-spinner__input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.number-spinner.demi-number-spinner .number-spinner__button,
.number-spinner.demi-number-spinner .number-spinner__input {
    border: none;
    border-bottom: 1px solid var(--color-gray);
}
.number-spinner.demi-number-spinner .number-spinner__button {
    background: white;
}
.number-spinner.demi-number-spinner .number-spinner__input {
    background: transparent;
    margin-left: 5px;
    margin-right: 5px;
}