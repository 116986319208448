.room-selection-box {
    padding: 20px;
    border: 1px solid var(--color-gray-border);
}

.room-row__image {
    min-height: 140px;
}

.room-row__price {
    font-size: 24px;
}

.room-row__select-room-btn,
.room-row__book-now-btn {
    font-size: 16px;
}

.room-row__border-left {
    border-left: 1px solid var(--color-gray-border);
}

.collapse-arrow {
    font-size: 6px;
    transform: rotate(180deg);
    transition: 120ms ease-out;
}
.collapsed .collapse-arrow {
    transform: rotate(0deg);
}