:root {
    --card-ribbon-bg-color: var(--color-primary--dark);
    --card-ribbon-color: #fff;
}

.card-ribbon {
    position: relative;
    margin-left: -5px;
    margin-right: -5px;
    padding: 13px 20px;
    background: var(--card-ribbon-bg-color);
    color: var(--card-ribbon-color);
}
.card-ribbon:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 5px 5px;
    border-color: transparent transparent color(var(--card-ribbon-bg-color) blackness(58%)) transparent;
}
.card-ribbon:after {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 0 0 5px;
    border-color: transparent transparent transparent color(var(--card-ribbon-bg-color) blackness(58%));
}

@media (min-width: 768px) {
    .card-body {
        padding: 1.5rem;
        display: block;
    }

    .card-ribbon {
        margin-left: -10px;
        margin-right: -10px;
    }

    .card-ribbon:before {
        border-width: 0 0 10px 10px;
    }

    .card-ribbon:after {
        border-width: 10px 0 0 10px;
    }


}

.card__info-row .demi-icon{
    margin-top: calc(2rem/16);
}