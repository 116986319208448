.btn,
button {
    cursor: pointer;
}

.btn-default {
    color: #333;
    background-color: var(--color-dark-medium-light);
}
.btn-default.active {
    color: #ffffff;
    background-color: var(--color-primary);
}
.btn-link:hover {
    text-decoration: underline;
}
.btn__icon {
    font-size: 16px;
    vertical-align: -.2em;
}
.btn__icon.demi-icon-arrow-right {
    font-size: 0.94em;
    vertical-align: -0.1em;
}
.btn__icon.demi-icon-facebook {
    font-size: 20px;
}
.btn__icon.demi-icon-person {
    vertical-align: -.15em;
}
.btn__icon.demi-icon-cart {
    vertical-align: -.1em;
}
.btn-white {
    background: white;
    color: var(--color-dark-gray);
}
.btn-white:hover,
.btn-white:focus,
.btn-white:active {
    color: black;
}

.icon-btn {
    line-height: 1;
}

.close-btn {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1;
    line-height: 1;
}

.btn-facebook,
.btn-facebook:hover,
.btn-facebook:focus {
    background: #3B5998;
    color: #ffffff;
}
.btn-google,
.btn-google:hover,
.btn-google:focus {
    background: #d34836;
    color: #ffffff;
}

.btn-show-gallery-container {
    position: relative;
}

.btn-show-gallery {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
    color: white;
    font-size: 20px;
    background: rgba(0,0,0,.6);
}
.btn-show-gallery__icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.btn-show-gallery--small {
    width: 30px;
    height: 30px;
    font-size: 16px;
}

.btn-breakable {
    white-space: normal;
}
