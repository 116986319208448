

@media (max-width: 767px) {
  .room-occupancy-compact__room-title {
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    font-size: 15px;
    margin-bottom: .5rem;
  }
}

@media (min-width: 768px) {
  .room-occupancy-compact__room-title {
    margin-left: .5rem;
    margin-top: 1.5rem;
  }
}