/* popover styling*/
.popover {
  min-width: 280px;
  box-shadow: 0 1px 6px 0 rgba(0,0,0,0.4);
  border-radius: 0;
}
.popover.popover--md {
  min-width: 350px;
}
.popover-body {
  padding: 0px;
}